export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_SIGN_UP = '/new-sign-up';
export const PATH_SIGN_UP_APPROVE = `${PATH_SIGN_UP}/approve`;
export const PATH_SIGN_UP_ACCOUNT_PERSONAL = `${PATH_SIGN_UP}/account-info`;
export const PATH_SIGN_UP_LOCATION_PERSONAL = `${PATH_SIGN_UP}/location-info`;
export const PATH_SIGN_UP_BUSINESS_INFO = `${PATH_SIGN_UP}/business-info`;
export const PATH_SIGN_UP_CONTROLLER_INFO = `${PATH_SIGN_UP}/controller-info`;
export const PATH_SIGN_UP_BENEFICIAL_OWNERS_INFO = `${PATH_SIGN_UP}/beneficial-owners-info`;
export const PATH_SIGN_UP_BANK_INFO = `${PATH_SIGN_UP}/bank-info`;
export const PATH_SIGN_UP_PAPER_CHECK_INFO = `${PATH_SIGN_UP}/paper-check-info`;
export const PATH_SIGN_UP_DOCUMENT = `${PATH_SIGN_UP}/document`;
export const PATH_SIGN_UP_IDENTITY_INFO = `${PATH_SIGN_UP}/identity-info`;
export const PATH_SIGN_UP_RETRY_PERSONAL = `${PATH_SIGN_UP}/retry/personal`;
export const PATH_SIGN_UP_RETRY_BUSINESS_SOLE_PROPRIETORSHIP = `${PATH_SIGN_UP}/retry/business-sole-proprietorship`;
export const PATH_SIGN_UP_RETRY_BUSINESS = `${PATH_SIGN_UP}/retry/business`;
export const PATH_SIGN_UP_VERIFICATION_IN_PROGRESS = `${PATH_SIGN_UP}/verification-in-progress`;
